import React, { Component } from 'react'
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import Layout from '../../components/layout'

import SEO from '../../components/seo'
import { ItineraryDetails } from '../../components/ItineraryDetails'

class ItineraryTemplate extends Component {
  render() {
    let post
    let randomItineraries = []

    if (this.props.preview) {
      post = this.props.data
    } else {
      post = this.props.data.wpItinerary
    }

    if (this.props.data.itineraryPool) {
      const shuffledItineraries = this.props.data.itineraryPool.nodes.sort(
        function() {
          return 0.5 - Math.random()
        }
      )
      randomItineraries = shuffledItineraries.slice(0, 3)
    }

    return (
      <Layout
        itinerary
        headerImage={post.avContent.featureImage}
        headerTitle={post.title}
        generic
        breadcrumb={{ name: 'itineraries', slug: '/things-to-do/itineraries' }}
      >
        <SEO
          title={post.seo.title}
          image={post?.avContent?.featureImage?.sourceUrl}
          description={post.seo.metaDesc}
        />

        <ItineraryDetails
          introText={post.avItinerary.introText}
          alpaca={post.avItinerary.alpaca}
          summary={post.avItinerary.summary}
          relatedItineraries={randomItineraries}
        />
      </Layout>
    )
  }
}

ItineraryTemplate.propTypes = {
  data: PropTypes.object.isRequired,
  edges: PropTypes.array,
}

export default ItineraryTemplate

export const pageQuery = graphql`
  query($id: String!) {
    itineraryPool: allWpItinerary(filter: { id: { ne: $id } }) {
      nodes {
        title
        uri
        avContent {
          featureImage {
            sourceUrl
            srcSet
          }
        }

        avItinerary {
          summary {
            days
          }
        }
      }
    }

    wpItinerary(id: { eq: $id }) {
      title
      seo {
        metaDesc
        title
      }
      avContent {
        featureImage {
          sourceUrl
          srcSet
        }
      }
      avItinerary {
        introText
        alpaca
        summary {
          days
        }
      }
    }
  }
`
