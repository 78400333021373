import React, { Component } from 'react'
import parser from 'html-react-parser'
import ItineraryMap from '../Map/ItineraryMap'

import { GetCatIcon } from '../../utils/NewCategoryicons'

import Activity from './activity.js'
import { ContactInformation } from '../ContactInformation'
import { RelatedArticles } from '../RelatedArticles'

import { Link } from 'react-scroll'

import {
  ComponentContainer,
  ContentStyles,
} from '../../utils/globalStyledComponents'

import {
  ItineraryContainer,
  IntroContainer,
  Calendar,
  OutCalendar,
  InfoWrapper,
  Item,
  DownloadPDF,
  FitnessLevel,
  Note,
} from './style.js'

class ItineraryDetails extends Component {
  render() {
    let activityCount = 0

    return (
      <div>
        <ComponentContainer>
          <IntroContainer>
            <OutCalendar>
              <Calendar>
                <p>{this.props.summary.days}</p>
                <span>day{this.props.summary.days > 1 && "s"}</span>
              </Calendar>
            </OutCalendar>

            <p>{this.props.introText}</p>
          </IntroContainer>

          {this.props.alpaca?.length > 0 && (
            <div>{parser(this.props.alpaca)}</div>
          )}
        </ComponentContainer>

        <RelatedArticles
          relatedPages={this.props.relatedItineraries}
          itineraries
          heading="Other itineraries"
        />
      </div>
    )
  }
}

export default ItineraryDetails
