import React, { Component } from 'react'

import { ItineraryStyles } from '../../utils/globalStyledComponents'
import { ReactComponent as TicketIcon } from '../../images/ticket-icon.svg'
import { AvenueImg } from '../AvenueImg'
import ItineraryCarousel from './itineraryCarousel'
import { getImageRatio } from '../../utils/helpers'

import {
  BookTicketsContainer,
  TicketIconBox,
  ContentContainer,
  TextContainer,
  HeaderContainer,
  NumberedBox,
  TitleWrapper,
  PlaceholderDiv,
  ImageContainer,
  LastActivityLine,
  ActivityWrapper,
} from './style.js'

import { ContactInformation } from '../ContactInformation'

class Activity extends Component {
  render() {
    // console.log(this.props.website)

    return (
      <div>
        {this.props.showGallery ? (
          <ItineraryCarousel
            gallery={this.props.gallery}
            firstActivity={this.props.firstActivity}
          />
        ) : (
          <PlaceholderDiv />
        )}
        <ActivityWrapper
          gallery={this.props.showGallery}
          booking={this.props.bookNow === 1}
          lastActivity={this.props.lastActivity}
          firstActivity={this.props.firstActivity}
          id={'activity' + this.props.activityNumber}
        >
          <ContentContainer>
            <TextContainer>
              <NumberedBox>
                <h5>{this.props.activityNumber}</h5>
              </NumberedBox>
              <HeaderContainer>
                <TitleWrapper>
                  <p>Day {this.props.dayNumber}</p>
                  <h2 dangerouslySetInnerHTML={{ __html: this.props.title }} />
                </TitleWrapper>
              </HeaderContainer>

              <ItineraryStyles
                dangerouslySetInnerHTML={{ __html: this.props.description }}
              />

              <ContactInformation
                ticketsFrom={this.props.ticketsFrom}
                itinerary
                address={this.props.address}
                accommodation={this.props.accommodation}
                openingHours={this.props.openingHours}
                website={this.props.website}
                path={this.props.path}
              />
            </TextContainer>

            <ItineraryStyles>
              <ImageContainer>
                {this.props.images &&
                  this.props.images.map((image, index) => {
                    const ratio = getImageRatio(image.srcSet)

                    {
                      /* <figcaption>A great way to start the day</figcaption> */
                    }
                    return (
                      <AvenueImg
                        key={index}
                        aspectRatio={ratio}
                        sizes="(min-width: 768px) 470px, 100vw"
                        src={image.url}
                        srcSet={image.srcSet}
                        alt={this.props.title}
                      />
                    )
                  })}
              </ImageContainer>
            </ItineraryStyles>
          </ContentContainer>
        </ActivityWrapper>

        {this.props.bookNow === 1 && (
          <BookTicketsContainer lastActivity={this.props.lastActivity}>
            <TicketIconBox>
              <TicketIcon />
            </TicketIconBox>

            <h3>Keen to book?</h3>
            <p>
              <a
                rel="noopener noreferrer"
                target="_blank"
                href={this.props.bookingLink}
              >
                Get your tickets here
              </a>
            </p>
          </BookTicketsContainer>
        )}

        <LastActivityLine lastActivity={this.props.lastActivity} />
      </div>
    )
  }
}

export default Activity
