import React, { Component } from 'react'
import ReactPlayer from 'react-player'
import { ResponsiveImage } from '../ResponsiveImage'

import '../../utils/slick.css'
import '../../utils/slick-theme.css'

import Slider from 'react-slick'

import {
  CarouselContainer,
  CarouselWrapper,
  CarouselPage,
  VideoWrapper,
} from './style.js'

class ItineraryCarousel extends Component {
  render() {
    const settings = {
      fade: true,
      infinite: true,
      speed: 700,
      lazyLoad: true,
      slidesToShow: 1,
      dots: true,
      autoplay: true,
      autoplaySpeed: 3000,
      slidesToScroll: 1,
      customPaging: function(i) {
        return <CarouselPage>{i + 1}</CarouselPage>
      },
    }

    return (
      <CarouselContainer firstActivity={this.props.firstActivity}>
        <CarouselWrapper>
          <Slider ref={c => (this.slider = c)} {...settings}>
            {this.props.gallery.map((slide, index) => {

							if (!slide.url) {
								return null
							}

              return slide.type === 'image' ? (
                <ResponsiveImage
                  key={index}
                  src={slide.image.url}
                  srcSet={slide.image.srcSet}
                  alt={slide.image.url}
                />
              ) : slide.type === 'video' ? (
                <VideoWrapper key={index}>
                  <ReactPlayer url={slide.video} />
                </VideoWrapper>
              ) : null
            })}
          </Slider>
        </CarouselWrapper>
      </CarouselContainer>
    )
  }
}

export default ItineraryCarousel
